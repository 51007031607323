@media (max-widht:768px) {
        .multiselectcomponent{
                border: 2px solid black !important;
        }
        .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
                border: 2px solid black;
        }
        
        

        
        
}