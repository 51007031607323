@media (max-width: 768px) {

    /* .d-flex {
      flex-wrap: wrap !important;
      justify-content: center !important;
    }
   */
    .body {
        overflow-y: hidden;
        margin: 0;
        padding: 0
    }

    .filter-left {
        text-align: center;
    }

    .date-pos {
        margin-bottom: 5px;
        width: 70px;
        min-width: 150px;
    }

    .filter-right {
        flex-direction: column !important;
        align-items: stretch !important;
        padding: 20px;
    }

    .MuiAutocomplete-root {
        width: 300px !important;
        padding: 5px 0 !important;
    }

    .custom2 {
        width: 250px !important;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
        right: -90px;
    }
    .datefield{
        width: 600px;
        position: relative;
        left: -70px;
    }
    .date-fltr{
        width: 150px;
    }

    .custom2 h4 {
        display: flex;
        justify-content: center;
        position: relative;
        right: 0px;
    }

    .button {
        width: 30%;
    }




    .date-fltr span {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .filter-icon-span {
        text-align: center;
    }

    .date-fltr {
        margin: 10px auto;
    }

    .MuiInputBase-root {
        width: 300px;
        min-width: 200px
    }
}

@media (max-width: 576px) {
    .filter-left h4 {
        font-size: 1rem;
    }

    .date-pos {
        padding: 1px 0;
        flex-direction: column;
    }

    .MuiInputBase-root {
        width: 300px;
        min-width: 200px
    }

    .date-fltr span {
        font-size: 0.9rem;
    }

    .filter-icon-span {
        font-size: 0.8rem;
    }

    .MuiAutocomplete-root {
        padding: 5px 0 !important;
    }
}