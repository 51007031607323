@media (max-width:768px){
        .filer_boxs{
                flex-direction: column;
                width: 100% !important;
                position: relative;
                top: -20px;
        }
        .filer_box{
                height: auto !important;
        }
        .filterheader{
                width: 100% !important;
                display: flex;
                align-items: center;
                justify-content: space-between;
        }
        .filterheader p{
                position: relative;
                top: 6px;
                left: 2px;
                
        }
        .clearfilter{
                width: 130px !important;
                height: 20px !important;
                position: relative;
                top: -5px;
                left: 48px;
        }
        .firstfield{
                flex-direction: column;
        }
        .head-div{
                position: relative;
                top: 20px;
                overflow-x: hidden !important;
                /* margin-top: 50px !important; */
                margin: 0px !important;
                padding-right: 13px;
                padding-left: 17px !important;
                width: 380px;
        }
        .mainheader{
                padding: 10px;
                display: flex;
                align-items: center;
        }
        .mainheader h2{
                font-size: 1.2rem;
        }
        .adminname{
                display: none;
                height: 10px !important;
                position: relative;
                top: -2px;
        }
        .mainheader svg{
                size: 1rem;
        }
        .mediasend{
                position: relative;
                left: -65px;
                top: 5px;
        }
       
        
}