/* new styling  */
@media (max-width: 768px) {
    .enter_head {
      /* padding: 20px; */
      padding-bottom: 50px;
      padding-top: 50px;
      text-align: center;
      height: auto;
      overflow: hidden;
    }
  
    .enter_head span {
      font-size: 16px;
      top: 5px;
      right: 5px;
    }
  
    .enter_head img {
      width: 100%;
      max-width: 200px;
      height: auto;
      margin: 10px auto;
    }
  
    .sub-head-div {
      display: flex;
      flex-direction: column;
      gap: 30px;
      align-items: center;
    }
  
    .sub-div, .comuni-sub-div, .user-sub-div {
      display: flex;
      width: 300px;
      height: auto;
      padding: 15px;
      border-radius: 20px;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .sub-div img, .comuni-sub-div img, .user-sub-div img {
      max-width: 150px;
      height: auto;
    }
  
    .sub-div button, .comuni-sub-div button, .user-sub-div button {
      width: 200px;
      height: 40px;
    }
  }
  
  @media (max-width: 576px) {
    .sub-div p, .comuni-sub-div p, .user-sub-div p {
      font-size: 14px;
    }
  
    .sub-div span, .comuni-sub-div span, .user-sub-div span {
      font-size: 12px;
    }
  
    .btn {
      font-size: 14px;
      padding: 10px;
    }
  }
  