@media (max-width: 768px) {
    
    .MuiSelect-select{
        width: 20px;
        border: 2px solid black;
    }
    
}
@media(max-width: 576px){
    .MuiSelect-select{
        width: 20px;
        border: 2px solid black;
    }

}
