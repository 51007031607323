@media (max-width:768px) {
     .table-container {
        border: 2px solid black ;
        width: 415px;
        position: relative;
        left: -55px;
        overflow: hidden !important;
    }



    /* .usermain {
        position: relative;
        top: 10px;usermain
        padding: 0;
    } */

    /* .table-container {
        width: 100%;
        padding: 0 !important;

    }
    .MuiFormControl-root{
        position: relative;
        left: -23px;
    }

    .px-5 {
        padding: 0 !important;
    }
     .container{
        width: 400px;
        position: relative;
        left: -58px;
    }
    .MuiPaper-root{
        width: 400px;

    }
    .tablecon{
        width: 400px;
    } */


}