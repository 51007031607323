@media (max-width:768px) {

    .admin-dashboard .admin-container{
        width: 100%;
        height: 100%;
        padding-left: 0px;
    

    }
    
}