@media (max-width: 768px) {
    /* .d-flex {
      flex-wrap: wrap !important;
      justify-content: center !important;
    }
   */
    .filter-left {
        text-align: center;
    }
  
    .date-pos {
      margin-bottom: 5px;
      width: 70px;
      min-width: 150px;
    }
  
    .filter-right {
      flex-direction: column !important;
      align-items: stretch !important;
    }
  
    .MuiAutocomplete-root{
      width: 300px !important;
      padding: 5px 0 !important;
    }
    .custom{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .button{
        width: 30%;
    }
    .heading{
        position: relative;
        right: -100px;
    }

  
    .date-fltr span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .filter-icon-span {
      text-align: center;
    }
  
    .date-fltr {
      margin: 10px auto;
    }
  }
  
  @media (max-width: 576px) {
    .filter-left h4 {
      font-size: 1rem;
    }
  
    .date-pos {
      padding: 1px 0;
      flex-direction: column;
    }
  
    .date-fltr span {
      font-size: 0.9rem;
    }
  
    .filter-icon-span {
      font-size: 0.8rem;
    }
  
    .MuiAutocomplete-root {
      padding: 5px 0 !important;
    }
  }

  
  