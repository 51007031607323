@media (max-width: 768px) {

        .textcontainer{
                width: 330px !important;
                
        }
        .companyusers form{
                padding: 0px !important;
                min-width: 300px !important;
        }
        .companyusers h1{
                font-size: 1.3rem;
                padding: 10px;
        }
        
}