@media (max-width:768px) {
        .mslect  {
                display: flex;
                justify-content: center;
        }
        .roleupdate{
                display: flex;
                justify-content: center;
        }
        .caregivertextfield{
                width: 93% !important;
                position: relative;
                left: 12px;
        }
        
}