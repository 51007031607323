@media (max-width: 768px) {
        .facilityuser{
                width: 350px !important;       
        }
        .facilityuser form{
                padding: 0px !important;
                min-width: 300px !important;
                position: relative;
                top: 100px;
        }
        .facilityform{
                width: 350px !important;
                position: relative;
                top: 200px;
        }
        .facilitycont{
                width: 320px !important;
        }
        .facilityuser h1{
                font-size: 1.3rem;
                padding: 10px;

        }

        
        
}