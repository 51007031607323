/* Responsive Styles */
@media (max-width: 768px) {
   
    .nav-inner-2 {
        flex-direction: column;
        align-items: flex-start;
        gap: 1px;
    }
    .nav-main-2{
       height: 65px;
    }
    .contentsmall{
        font-size: 18px !important;
    }
    .modalbutten {
        display: flex;
        justify-content: space-between;
    }
    .timetra-heading{
        display: none;
        position: relative;
        left: -170px;
    }

    .auth-nav-icon {
        width: 100%;
        display: flex;
      position: relative;
      top: -7px;
      left: 5px;
    }

    .auth-profile-pop {
        width: 100%;
        height: 2px;
        /* border: 1px solid black; */
        padding: 5px;
       position: relative;
       top: -20px;
        /* flex-direction: row-reverse; */
    }
    .bdge-auth{
        position: relative;
        top: -32px;
        left: 120px;
        width: 40px;
        height: 40px; 
    }
    .bdge-auth p{
        font-size: 16px;
       position: relative;
       top: -5px;
    }
    .define-iam{
        position: relative;
        left: 23px;
        top: -2px;

    }
    .auth-name-nav{
        position: relative;
        top: 8px;
    }

    .auth-pro-group {
        display: none;
        
    }

    .logout {
        font-size: 2rem;
        position: relative;
        top: -12px;
    }
   
}