@media (max-width: 768px) {
    .login-box{
        width: 90%;
    }
    .nav-inner{
        width: 165px;
        display: flex;
        justify-content: space-between;
       align-items: center;
    }
}