@media (max-width:768px) {
        .mainuser form {
                min-width: 397px !important;
                position: relative;
                left: 0px;
        }
        .mainuser{
                width: 320px !important;
                overflow: hidden;
        }
        .mainuser h1{
                font-size: 1.3rem;
                padding: 10px;
        }
       
        
}