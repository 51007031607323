@media (max-width: 768px) {
        .punchmodel{
                /* border: 2px solid black; */
                padding: 0;

        }
        .selectsingle{
                width: 500px;
                position: relative;
                left: 9px;
        }
}