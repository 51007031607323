@media (max-width: 768px) {
      
        .MuiTablePagination-toolbar {
          flex-direction: column;
          
        }
        .MuiToolbar-root{
          height: 35px !important;
        }

        .MuiTablePagination-actions{
          position: relative;
          left: -60px;
          top: -45px;
        }
        .MuiTablePagination-displayedRows {
          position: relative;
          left: -118px;
          top: -20px;
        }

        .MuiTablePagination-selectLabel{
          position: relative;
          left: 60px;
          top: 18px;
        }

        .MuiTablePagination-spacer {
         
          border: 0ca2px solid black;
        }
      }