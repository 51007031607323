@media (max-width:768px) {
        .singleadmin{
                display: none;
        }
        .singleheaddiv h2{
                font-size: 1.2rem;
        }
        .singleheaddiv{
               padding: 10px;
        }
        
}