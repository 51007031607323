@media (max-width:768px) {
        .patientsidebar{
                display: flex;
                max-width: 380px !important;
                width: 100%;
                height: 50px !important;
                z-index: 10;
        }
        .patientsidebar img{
                position: relative;
                top: -19px;
                left: -10px;
                width: 100px;
                font-size: 0.5rem;
        }
        .sidebarlist{
                display: flex !important;
                width: 20px;
                height: 10px !important;
                
        }
        .hidecomponent{
                display: none;
        }
        .logoutdiv{
                font-size: 1.7rem !important;
                position: relative;
                right: -110px;
                top: -14px;
        }
        .hid-div{
                display: none;
        }
}