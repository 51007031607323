@media (max-width:768px) {
       
         .systemuser form {
                padding: 0px !important;
                min-width: 340px !important;
        }
        .systemcon{
                width: 320px !important;
               

        }
        .systemintegration{
                padding: 20px;
        }
}