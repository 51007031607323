@media (max-width: 768px) {
        .facilitycontainer{
                flex-direction: column;
                padding: 10px;
                position: relative;
                top: -20px;
        }
        .facilitysearch{
                position: relative;
                left: -50px;
        } .facilitycontainer{
                flex-direction: column;
                padding: 10px;
                position: relative;
                top: -20px;
        }
        .facilitysearch{
                position: relative;
                left: -50px;
        }
        .facilityautocomplete{
                position: relative;
                left: -120px;
        }
        .facilityrow{
               position: relative;
               left:6px;
                
        }
        .facilityautocomplete{
                position: relative;
                left: -120px;
        }
        .facilityrow{
               position: relative;
               left:6px;
                
        }
        
}
