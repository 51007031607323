@media (max-width:768px) {
       .newfieldtext{
        padding: 20px;
       }
       .rowtop{
        position: relative;
        top: -15px;
       }
       .autumntracknew{
        border: 2px solid black;
        width: 318px !important;
        position: relative;
        left: 14px;
        width: 40px;
        
       }
       .newautumntracknew{
              display: flex;
              align-items: center;

              height: 50px !important;
       }
        
}