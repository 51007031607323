@media (max-width:768px) {
        .agencyuser form {
                width: 500px !important;
        }
        .webhookfield{
                width: 340px !important;
        }
        .admin-dashboard .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
                width: 100% !important;
        }
        .agencyuser h1{
                font-size: 1.3rem;
        }
}