


@media (max-width: 768px) {
        .searchuser{
                display: flex;
                justify-content: center;
        }
        .sssingal{
                min-width: 100% !important;
                display: flex;
                justify-content: center;
        }
        .ttfield{
                padding: 15px;
        }
        .rowalign{
                margin-top: 0 !important;
        }
        .rowalign2{
                position: relative;
                top: -40px;
        }
        .rowalign4{
                position: relative;
                top: -20px;
        }
        .usertextfield{
                width: 93% !important;
                position: relative;
                left: 11px;
        }
       
        
        
}