@media (max-width:768px) {
        .usercreate{
                width: 60% !important;

        }
        .usercreate h1{
                width: 200px;
                font-size: 1.3rem;
                position: relative;
                left: 180px;
                top: -20px;
        }

        .container2{
                width: 320px !important;
                border: 2px soild black;
                position: relative;
                left: 72px;
                top: -20px;
        }
       
}